import React from 'react'
import { graphql } from 'gatsby'

import { mapEdgesToNodes } from '../lib/helpers'
import { PostList } from '../components/PostList'
import { GraphQLErrorList } from '../components/Graphql-error-list'
import { SEO } from '../components/SEO'
import { LayoutContainer } from '../containers/Layout'

export const query = graphql`
  query ArchivePageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          images {
            ...SanityImage
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          slug {
            current
          }
        }
      }
    }
  }
`

const ArchivePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)

  return (
    <LayoutContainer>
      <SEO title="Archive" />
      <h1>Archive</h1>
      {postNodes && postNodes.length > 0 && <PostList nodes={postNodes} />}
    </LayoutContainer>
  )
}

export default ArchivePage
