/** @jsx jsx */
import { Grid, jsx } from 'theme-ui'
import { Post } from './Post'

export const PostList = props => {
  return (
    props.nodes && (
      <Grid
        sx={{
          columnGap: 0,
          rowGap: 6
        }}
      >
        {props.nodes.map(node => (
          <Post
            {...node}
            key={node.id}
            allHighlights={props.allHighlights}
            handleHighlight={props.handleHighlight}
          />
        ))}
      </Grid>
    )
  )
}
